import { Appointment, debounce, parseUTC, Pharmacy, PharmacyStatus, useAbilities } from '@aposphaere/core-kit'
import {
  ApoCampusIcon,
  Button,
  ButtonKind,
  CloseIcon,
  DangerIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  LeftSidebar,
  LockedForCallsIcon,
  PhoneIcon,
  PlusSignIcon,
  ReminderIcon,
  SwitchOnIcon,
  TabBar,
  TabBarItem,
  TabBarItemKind,
} from '@aposphaere/ui-components'
import { isFuture, isPast, isToday, min, max, format, parse } from 'date-fns'
import React, { useCallback, useMemo, useRef } from 'react'
import { useCrmContext } from '../../contexts/crmContext'
import { ModalKind, useModal } from '../../contexts/modalContext'
import { useAppointmentsQuery, useAvailableForCallcenterMutation, usePharmaciesQuery, usePharmacyQuery, useProjectsQuery, useAddCallToPharmacy } from '../../hooks/graphql'
import { getOpeningHours } from '../../utils/openingHours'
import AppointmentsCard from '../AppointmentsCard'
import HistoryCard from '../HistoryCard'
import NotesCard from '../NotesCard'
import PersonenCard from '../PersonenCard'
import PharmaciesCard from '../PharmaciesCard'
import ProjectsCard from '../ProjectsCard'
import SlotCard from '../SlotCard'
import ActivityLogCard from '../ActivityLogCard'
import { IPointType } from './PharmacyDataSheetTypes'
import { useQuartersQuery } from '../../hooks/graphql'

interface Props {
  pharmacyId: number
  setPharmacyId: (id: number) => void
  onCreateAppointment: () => void
  onClose: () => void
}

const PharmacyDataSheetPanel = ({ pharmacyId, onClose, setPharmacyId, onCreateAppointment }: Props) => {
  const abilities = useAbilities()

  const context = useCrmContext()
  const {
    currentPointHook: { currentPoint, setCurrentPoint },
    isUserCallcenter,
  } = context

  const { openModal } = useModal()

  const { mutate: setAvailableForCallcenter } = useAvailableForCallcenterMutation()
  const { mutate: addCallToPharmacyId } = useAddCallToPharmacy()

  const { data: detailedPharmacy } = usePharmacyQuery(pharmacyId)
  const { data: pharmacies } = usePharmaciesQuery()

  const pharmacy = useMemo(() => {
    if (detailedPharmacy) {
      return detailedPharmacy
    }

    return pharmacies?.find((_pharmacy) => _pharmacy.id === pharmacyId) ?? null
  }, [detailedPharmacy, pharmacies, pharmacyId])

  const infoRef = useRef<HTMLHeadingElement>(null)
  const notizenRef = useRef<HTMLHeadingElement>(null)
  const resubmissionsRef = useRef<HTMLHeadingElement>(null)
  const filialenRef = useRef<HTMLHeadingElement>(null)
  const termineRef = useRef<HTMLHeadingElement>(null)
  const projekteRef = useRef<HTMLHeadingElement>(null)
  const personenRef = useRef<HTMLHeadingElement>(null)
  const windowRef = useRef<HTMLHeadingElement>(null)
  const slotRef = useRef<HTMLDivElement>(null)
  const historyRef = useRef<HTMLDivElement>(null)
  const activitiesRef = useRef<HTMLDivElement>(null)

  const { data: projects } = useProjectsQuery()
  
  const quartersQuery = useQuartersQuery()

  const startingDates = quartersQuery.data?.filter((quarter) => quarter?.from).map((quarter) => parseUTC(quarter!.from!))
  const endingDates = quartersQuery.data?.filter((quarter) => quarter?.to).map((quarter) => parseUTC(quarter!.to!))

  const minDate = min(startingDates || [])
  const maxDate = max(endingDates || [])
  const date = { from: format(minDate, 'yyyy-MM-dd'), to: format(maxDate, 'yyyy-MM-dd') }

  const { data: appointments } = useAppointmentsQuery({ date: date })

  const openingHours = getOpeningHours(pharmacy?.opening_times)

  const isBlockedForCallcenter = pharmacy?.available_for_callcenter === false
  const isInactive = pharmacy?.pharmacy_status === PharmacyStatus.Inactive
  const withWarning = isBlockedForCallcenter || isInactive
  const warningText =
    pharmacy?.pharmacy_status === PharmacyStatus.Active
      ? 'Callcenter - Sperre aktiv: Dieser Standort darf nicht kontaktiert werden.'
      : 'Apotheke wurde deaktiviert'

  const [futureAppointments, pastAppointments] = useMemo<[Appointment[], Appointment[]]>(() => {
    const pharmacyAppointments: Appointment[] =
      appointments?.filter((appointment) => appointment.pharmacy?.id === pharmacy?.id && `${appointment.appointmentType?.id || 0}` !== '2') || []
    const future = pharmacyAppointments.filter((appointment) => appointment.date && isFuture(new Date(appointment.date)))
    const past = pharmacyAppointments.filter((appointment) => appointment.date && isPast(new Date(appointment.date)))
    return [future, past]
  }, [appointments, pharmacy])

  const [futureVisits, pastVisits] = useMemo<[Appointment[], Appointment[]]>(() => {
    const currentVisits = appointments?.filter((appointment) => `${appointment.appointmentType?.id || 0}` === '2') || []
    const pharmacyVisits: Appointment[] = currentVisits?.filter((appointment) => appointment.pharmacy?.id === pharmacy?.id) || []
    const future = pharmacyVisits.filter((appointment) => appointment.date && isFuture(new Date(appointment.date)))
    const past = pharmacyVisits.filter((appointment) => appointment.date && isPast(new Date(appointment.date)))

    return [future, past]
  }, [appointments, pharmacy])

  const mainBranch = useMemo<Pharmacy | undefined>(() => {
    if (!pharmacies || !pharmacy || !pharmacy.parent?.id) {
      return undefined
    }
    let left = 0
    let right = pharmacies.length - 1
    let mid = Math.floor((left + right) / 2)

    if (`${pharmacies[mid].id}` === `${pharmacy.parent.id}`) {
      return pharmacies[mid]
    }
    while (pharmacies[mid].id !== pharmacy.parent.id && left <= right) {
      if (parseInt(`${pharmacies[mid].id}`) < parseInt(`${pharmacy.parent.id}`)) {
        left = mid + 1
      } else {
        right = mid - 1
      }
      mid = Math.floor((left + right) / 2)
    }
    return pharmacies[mid].id === pharmacy.parent.id ? pharmacies[mid] : undefined
  }, [pharmacy, pharmacies])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkThePointSelected = useCallback(
    debounce((point: IPointType) => {
      if (currentPoint !== point) {
        setCurrentPoint(point)
      }
    }, 500),
    [currentPoint],
  )

  const lastCallPharmacyText = useMemo(() => {
    const parseDate = pharmacy?.call ? parse(pharmacy.call.created_at, 'yyyy-MM-dd HH:mm:ss', new Date()) : undefined
    const userName = pharmacy?.call?.user?.name || 'unbekannt'
    const formattedDate = parseDate ? format(parseDate, 'dd.MM.yyyy HH:mm') : 'unbekanntes Datum'

    return `Letzter Anruf durch ${userName} am ${formattedDate}`
  }, [pharmacy?.call])


  const executeScroll = useCallback(
    (ref: React.RefObject<HTMLHeadingElement>, point: IPointType) => {
      if (ref && windowRef && ref.current && windowRef.current) {
        windowRef.current.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 20 })
        checkThePointSelected(point)
      }
    },
    [windowRef, checkThePointSelected],
  )

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (
        !infoRef.current ||
        !notizenRef.current ||
        !resubmissionsRef.current ||
        !filialenRef.current ||
        !termineRef.current ||
        !projekteRef.current ||
        !slotRef.current ||
        !historyRef.current ||
        !activitiesRef.current ||
        !personenRef.current
      ) {
        return
      }
      const offsetTopInfo = infoRef.current.offsetTop - 15
      const offsetTopNotezen = notizenRef.current.offsetTop - 25
      const offsetTopResubmissions = resubmissionsRef.current.offsetTop - 25
      const offsetTopFilialen = filialenRef.current.offsetTop - 25
      const offsetTopTermine = termineRef.current.offsetTop - 25
      const offsetTopProjekte = projekteRef.current.offsetTop - 25
      const offsetTopPersonen = personenRef.current.offsetTop - 25
      const offsetTopSlot = personenRef.current.offsetTop - 25
      const offsetTopHistory = historyRef.current.offsetTop - 25
      const offsetTopActivities = activitiesRef.current.offsetTop - 25

      const currentScrollTop = Math.floor(e.currentTarget.scrollTop)
      const maxScrollTop = e.currentTarget.scrollHeight - e.currentTarget.clientHeight

      if (offsetTopInfo + 30 >= currentScrollTop && currentScrollTop >= offsetTopInfo - 30) {
        if (currentPoint !== 'info') {
          setCurrentPoint('info')
        }
      } else if (offsetTopNotezen + 30 >= currentScrollTop && currentScrollTop >= offsetTopNotezen - 30) {
        if (currentPoint !== 'notizen') {
          setCurrentPoint('notizen')
        }
      } else if (offsetTopResubmissions + 30 >= currentScrollTop && currentScrollTop >= offsetTopResubmissions - 30) {
        if (currentPoint !== 'wiedervorlagen') {
          setCurrentPoint('wiedervorlagen')
        }
      } else if (offsetTopFilialen + 30 >= currentScrollTop && currentScrollTop >= offsetTopFilialen - 30) {
        if (currentPoint !== 'filialen') {
          setCurrentPoint('filialen')
        }
      } else if (offsetTopTermine + 30 >= currentScrollTop && currentScrollTop >= offsetTopTermine - 30) {
        if (currentPoint !== 'termine') {
          setCurrentPoint('termine')
        }
      } else if (offsetTopProjekte + 30 >= currentScrollTop && currentScrollTop >= offsetTopProjekte - 30) {
        if (currentPoint !== 'projekte') {
          setCurrentPoint('projekte')
        }
      } else if (offsetTopPersonen + 30 >= currentScrollTop && currentScrollTop >= offsetTopPersonen - 30) {
        if (currentPoint !== 'personen') {
          setCurrentPoint('personen')
        }
      } else if (offsetTopSlot + 30 >= currentScrollTop && currentScrollTop >= offsetTopSlot - 30) {
        if (currentPoint !== 'slot') {
          setCurrentPoint('slot')
        }
      } else if (offsetTopHistory + 30 >= currentScrollTop && currentScrollTop >= offsetTopHistory - 30) {
        if (currentPoint !== 'history') {
          setCurrentPoint('history')
        }
      } else if (offsetTopActivities + 30 >= currentScrollTop && currentScrollTop >= offsetTopActivities - 30) {
        if (currentPoint !== 'activities') {
          setCurrentPoint('activities')
        }
      }
      if (currentScrollTop === maxScrollTop) {
        if (currentPoint !== 'personen') {
          setCurrentPoint('personen')
        }
      }
    },
    [currentPoint, resubmissionsRef, filialenRef, infoRef, notizenRef, personenRef, projekteRef, activitiesRef, termineRef, setCurrentPoint],
  )

  const additionalCss = pharmacy?.tasks?.filter((task) => task?.until && (isToday(new Date(task.until)) || isFuture(new Date(task.until)))).length
    ? 'text-yellow-600'
    : ''

  const branchLabel = mainBranch ? 'Hauptfiliale' : 'Filialen'

  if (!pharmacy) {
    return null
  }

  return (
    <LeftSidebar
      onScroll={handleScroll}
      ref={windowRef}
      headerContent={
        <div className="flex w-full flex-wrap font-body">
          <div className="w-full flex items-center p-2 space-between">
            <div className="flex xl:w-2/3 w-full items-center">
              <h4 className={`text-base ${withWarning ? 'text-red-700' : 'text-blue-700'} leading-6 font-medium`}>{pharmacy?.name}</h4>
            </div>
            <div className="flex w-full xl:w-2/3 justify-end pr-2 text-gray-700 items-center">
              {abilities.create_appointments && (
                <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon dimension="30" />} onClick={onCreateAppointment} />
              )}
              {abilities.create_tasks && (
                <IconButton
                  additionalCss={additionalCss}
                  kind={IconButtonKind.default}
                  icon={<ReminderIcon dimension="30" />}
                  onClick={() => openModal({ kind: ModalKind.ReminderCreate, pharmacyId })}
                />
              )}
              {abilities.update_pharmacies_callcenter_access && (
                <IconButton
                  kind={IconButtonKind.default}
                  additionalCss={isBlockedForCallcenter ? 'text-red-700' : ''}
                  icon={<LockedForCallsIcon dimension="30" />}
                  onClick={() => setAvailableForCallcenter({ id: pharmacy?.id, available_for_callcenter: !pharmacy.available_for_callcenter })}
                />
              )}
              {abilities.update_pharmacies_callcenter_access && (
                <IconButton
                  kind={IconButtonKind.default}
                  icon={<EditIcon dimension="30" />}
                  onClick={() => openModal({ kind: ModalKind.PharmacyUpdate, id: pharmacyId })}
                />
              )}
              {abilities.disable_pharmacies && (
                <IconButton
                  kind={IconButtonKind.default}
                  additionalCss={isInactive ? 'text-red-700' : ''}
                  icon={<SwitchOnIcon dimension="30" />}
                  onClick={() => openModal({ kind: ModalKind.PharmacyDeactivate, id: pharmacyId, status: pharmacy?.pharmacy_status })}
                />
              )}
              <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-2" />
              <IconButton kind={IconButtonKind.default} icon={<CloseIcon dimension="30" />} onClick={onClose} />
            </div>
          </div>
          <div className="flex w-full py-2 px-2 bg-white border-t border-gray-400 overflow-x-auto">
            <TabBar>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'info'}
                onClick={() => {
                  executeScroll(infoRef, 'info')
                }}
              >
                {'Info'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'notizen'}
                onClick={() => {
                  executeScroll(notizenRef, 'notizen')
                }}
              >
                {'Notizen'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'wiedervorlagen'}
                onClick={() => {
                  executeScroll(resubmissionsRef, 'wiedervorlagen')
                }}
              >
                {'Wiedervorlagen'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'filialen'}
                onClick={() => {
                  executeScroll(filialenRef, 'filialen')
                }}
              >
                {branchLabel}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'termine'}
                onClick={() => {
                  executeScroll(termineRef, 'termine')
                }}
              >
                {'Termine'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'projekte'}
                onClick={() => {
                  executeScroll(projekteRef, 'projekte')
                }}
              >
                {'Projekte'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'slot'}
                onClick={() => {
                  executeScroll(slotRef, 'slot')
                }}
              >
                {'Slots'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'history'}
                onClick={() => {
                  executeScroll(slotRef, 'history')
                }}
              >
                {'Historie'}
              </TabBarItem>
              {!isUserCallcenter && (
                <TabBarItem
                  kind={TabBarItemKind.secondary}
                  selected={currentPoint === 'activities'}
                  onClick={() => {
                    executeScroll(activitiesRef, 'activities')
                  }}
                >
                  {'Aktivitätslog'}
                </TabBarItem>
              )}
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'personen'}
                onClick={() => {
                  executeScroll(personenRef, 'personen')
                }}
              >
                {'Personen'}
              </TabBarItem>
            </TabBar>
          </div>
        </div>
      }
      showMax
    >
      <div className="relative z-30 bg-white w-100">
        <div className="w-full 2xl:px-6 px-3">
          {withWarning && (
            <div className="my-4">
              <div className={`p-2 rounded-lg bg-red-600 shadow-xl sm:p-3`}>
                <div className="flex items-center justify-start">
                  <span className="flex p-2 rounded-lg text-white">
                    <DangerIcon />
                  </span>
                  <p className="ml-2 text-white">{warningText}</p>
                </div>
              </div>
            </div>
          )}
          <div className="font-body pr-0 pt-6 pb-2 border-b-4 border-gray-300 last:border-b-0">
            <h2 ref={infoRef} className="text-lg font-medium mb-8">
              {'Allgemeine Informationen'}
            </h2>
            <div className="flex flex-wrap">
              <div className="w-1/2">
                <div className="text-base mb-6 leading-6">
                  {`ID: ${pharmacy?.id || ''} / ${pharmacy?.okid || ''}`}
                  <br />
                  {`Registrierungscode: ${pharmacy?.campus_registration_code || '-'}`}
                </div>
                <div className="text-base mb-6 pr-6">
                  <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">{'Adresse:'}</span>
                  <div className="flex flex-wrap">
                    <span className="w-full">{pharmacy?.name}</span>
                    <span className="w-full">{pharmacy?.address?.address_name}</span>
                    <span className="w-full">
                      {pharmacy?.address?.zipcode} {pharmacy?.address?.city}
                    </span>
                    <br />
                    {!!pharmacy.url && (
                      <p className="w-full break-words text-blue-700">
                        <a className="cursor-pointer" href={pharmacy.url}>
                          {pharmacy.url}
                        </a>
                      </p>
                    )}
                    {!!pharmacy.facebook && (
                      <p className="w-full break-words text-blue-700">
                        <a className="cursor-pointer" href={pharmacy.facebook}>
                          Facebook
                        </a>
                      </p>
                    )}
                  </div>
                </div>
                <div className="text-base mb-6">
                  <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">{'Kontakt:'}</span>
                  <div className="flex flex-wrap">
                      <div className="flex flex-row gap-4">
                        <span className="w-full">{`Ansprechpartner: ${pharmacy?.contact_person ? pharmacy?.contact_person : 'kein'}`}</span>
                        <IconButton
                          kind={IconButtonKind.custom}
                          icon={<EditIcon dimension="20" />}
                          onClick={() => openModal({ kind: ModalKind.UpdateContactModal, pharmacyId })}
                        />
                      </div>
                    {!!pharmacy.owner && <span className="w-full">{`Inhaber: ${pharmacy?.owner}`}</span>}
                    <a
                      href={`tel:${pharmacy.phone}`}
                      onClick={async () => {
                        await addCallToPharmacyId({ pharmacy_id: pharmacy.id.toString() })
                      }}
                    >
                      {`Tel.: ${pharmacy.phone || '_'}`}
                    </a>
                    {!!pharmacy.email && (
                      <p className="w-full break-words">
                        <a
                          className="cursor-pointer text-blue-700"
                          aria-disabled={!pharmacy?.email}
                          href={pharmacy.email ? `mailto:${pharmacy.email}` : undefined}
                        >
                          {pharmacy.email}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-base mb-6">
                  <a href={pharmacy?.email ? `mailto:${pharmacy?.email}` : undefined} aria-disabled={!pharmacy?.email}>
                    <Button disabled={!pharmacy?.email} icon={<ApoCampusIcon />} kind={ButtonKind.outlinedPrimary}>
                      {'ApoCampus Mail'}
                    </Button>
                  </a>
                </div>
                <div className="text-base mb-6" onClick={() => openModal({ kind: ModalKind.PharmacyOneKeyUpdate, id: +pharmacy.id })}>
                  <Button disabled={pharmacy.open_ok_request} kind={ButtonKind.outlinedPrimary} icon={<EditIcon dimension="30" />}>
                    {'Apotheke ändern'}
                  </Button>
                </div>
                <div className="text-base mb-6">
                  <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">{'Quelle:'}</span>
                  <div className="flex flex-wrap">
                    <span className="w-full">{'IQVIA'}</span>
                  </div>
                </div>
                {!!pharmacy?.opening_times ? (
                  <div className="text-base mb-6">
                    <div className="flex flex-row gap-1">
                      <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">{'Öffnungszeiten:'}</span>
                      <IconButton
                        kind={IconButtonKind.custom}
                        icon={<EditIcon dimension="20" />}
                        onClick={() => openModal({ kind: ModalKind.UpdateOpentimesModal, pharmacyId })}
                      />
                    </div>
                    <div className="leading-6">
                      {openingHours.map((text, i) => (
                        <div className="flex mb-1" key={i}>
                          {text.length === 1 ? (
                            <span>{text[0]}</span>
                          ) : (
                            <>
                              <span className="text-sm">{text[0]}</span>
                              <span className="flex-1 text-sm">{text[1]}</span>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="flex w-full py-3 pl-4 pr-6 my-2 rounded-md bg-blue-100 text-blue-700 items-center">
                <PhoneIcon />
                {pharmacy.call ? (
                    <span className="pl-4">{lastCallPharmacyText}</span>
                  ) : (
                    <span className="pl-4">{'Letzte Anwahl via CRM: Hier können ihre Daten stehen'}</span>
                  )}
              </div>
            </div>
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300">
            <h2 ref={notizenRef} className="text-lg font-medium my-6">
              {'Notizen'}
            </h2>
            <NotesCard pharmacyId={pharmacyId} notes={pharmacy?.notes} showAddButton={abilities.create_notes} />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300">
            <h2 ref={resubmissionsRef} className="text-lg font-medium my-6">
              {'Wiedervorlagen'}
            </h2>
            <NotesCard pharmacyId={pharmacyId} reminders={pharmacy?.tasks} showAddButton={abilities.create_tasks} />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300">
            <h2 ref={filialenRef} className="text-lg font-medium my-6">
              {branchLabel}
            </h2>
            <PharmaciesCard
              title={branchLabel}
              pharmacies={mainBranch ? [mainBranch] : pharmacy && pharmacy.branches ? pharmacy.branches : []}
              showAddButton={false}
              setSelectedPharmacyId={setPharmacyId}
            />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0">
            <h2 ref={termineRef} className="text-lg font-medium my-6">
              {'Termine'}
            </h2>
            <AppointmentsCard
              projects={projects || []}
              showAllEntries={true}
              title="Kommende Trainingstermine"
              canEditDelete={abilities.edit_appointments}
              appointments={futureAppointments || []}
              showAddButton={false}
            />

            <AppointmentsCard
              projects={projects || []}
              showAllEntries={true}
              title="Anstehende Besuchstermine"
              canEditDelete={abilities.edit_appointments}
              appointments={futureVisits || []}
              showAddButton={false}
            />
            <AppointmentsCard
              projects={projects || []}
              showAllEntries={false}
              presentationsCouldBe
              canEditDelete={abilities.edit_appointments}
              title="Abgeschlossene Termine"
              appointments={pastAppointments === undefined ? [] : pastAppointments}
              showAddButton={false}
            />
            <AppointmentsCard
              projects={projects || []}
              showAllEntries={false}
              title="Abgeschlossene Besuchstermine"
              canEditDelete={abilities.edit_appointments}
              appointments={pastVisits || []}
              showAddButton={false}
            />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0 last:mb-16">
            <h2 ref={projekteRef} className="text-lg font-medium my-6">
              {'Projekte'}
            </h2>
            <ProjectsCard pharmacyId={pharmacyId} showAddButton={false} showSpecialButton={false} />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0 last:mb-16">
            <h2 ref={slotRef} className="text-lg font-medium my-6">
              {'Slots'}
            </h2>
            <SlotCard pharmacy={pharmacy} showSpecialButton={false} />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0 last:mb-16">
            <h2 ref={historyRef} className="text-lg font-medium my-6">
              {'Historie'}
            </h2>
            <HistoryCard appointments={appointments ?? []} pharmacy={pharmacy} showSpecialButton={false} />
          </div>
          {!isUserCallcenter && (
            <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0 last:mb-16">
              <h2 ref={activitiesRef} className="text-lg font-medium my-6">
                {'Aktivitätslog'}
              </h2>
              <ActivityLogCard pharmacy={pharmacy} />
            </div>
          )}
          <div className="font-body pr-0 pb-60 border-b-4 border-gray-300 last:border-b-0 last:mb-16">
            <h2 ref={personenRef} className="text-lg font-medium my-6">
              {'Personen'}
            </h2>
            <PersonenCard pharmacyId={pharmacy.id} employees={pharmacy?.employees || []} showAddButton={false} />
          </div>
        </div>
      </div>
    </LeftSidebar>
  )
}
export default PharmacyDataSheetPanel
